import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { EducateModule } from './educate/educate.module';
import { AppComponent } from './app.component';
import {SharedModulesModule} from './shared-modules/shared-modules.module';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ThankYouPageComponent } from './contact-us/thank-you-page/thank-you-page.component';
import { ErrorPageComponent } from './error-page/error-page.component';
const routes: Routes = [

  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'error-page', component: ErrorPageComponent },
  { path: 'home', loadChildren: () => import('src/app/home/home.module').then(m => m.HomeModule) },
  { path: 'privacy-policy', loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },
  { path: 'terms-conditions', loadChildren: () => import('./terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule) }, 
  { path: 'cookie-policy', loadChildren: () => import('./cookie-policy/cookie-policy.module').then(m => m.CookiePolicyModule) },
  { path: 'how-to-complain', loadChildren: () => import('./how-to-complain/how-to-complain.module').then(m => m.howtocomplainModule) },
  { path: 'faqs', loadChildren: () => import('./faq/faq.module').then(m => m.faqModule) },
  { path: 'for-employers', loadChildren: () => import('./for-empolyers/for-empolyers.module').then(m => m.forempolyersModule) },
  { path: 'about-us', loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsModule) },
  { path: 'contact-us', loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsModule) },
  { path: 'resources', loadChildren: () => import('./educate/educate.module').then(m => m.EducateModule) },
  { path: 'thank-you', component:ThankYouPageComponent },

];

@NgModule({
  imports: [
    BrowserTransferStateModule,
    BrowserModule.withServerTransition({ appId: 'tour-of-heroes' }),
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    EducateModule,
    SharedModulesModule,
    RouterModule.forRoot(routes, { initialNavigation: 'enabled' }),
  ],
  declarations: [
    AppComponent
  ],
  providers: [],
  bootstrap: [ AppComponent ],
  exports: [
    SharedModulesModule
  ],
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(APP_ID) private appId: string) {
    const platform = isPlatformBrowser(platformId) ?
      'in the browser' : 'on the server';
    console.log(`Running ${platform} with appId=${appId}`);
  }
}
