import { CategoryText, Data } from './../../Models/ArticleModel';
import { ArticleService } from './../../Service/article.service';
import { Component, OnInit } from '@angular/core';
import { ArticalCategory, ArticleModel } from '../../Models/ArticleModel';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
const categoryViewAllId: string = '8LKiebfkdipkcKuWqhUzwu9xLOQ';
const topPageId: string = '5TNZcRn4xOxtTro6yaeD4s';
const tradingArticleId: string = '5Sp3AG6KT8EJGsoYbYyIYF';
@Component({
  selector: 'app-resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.css']
})
export class ResourceComponent implements OnInit {
  imgSrc = environment.imgBasURL;
  categories: ArticalCategory[] = [];
  CategoryLength: number = 0;
  RestCategoryCount: number = 0;
  articles: ArticleModel[] = [];
  totalArticleCount:number=0;
  SelectectArticleIds: Array<string> = [];
  IsArticleloaded: boolean = false;
  filtersCategories: ArticalCategory[];
  searchBox: string = "";
  ArticleCount: number;
  TopArticle: any;
  TradingArticle: any;
  ArticleNumber: number =6;
  TradingText: any;
  selectedCategories:any[]=[];
  articlesLoading: boolean = true;
  filteredArticles:any[]=[];
  articleIncrementForMobile: number = 6; 
  public isMobileLayout = false;
  Loader:boolean = true;
  constructor(private cmsService: ArticleService, private router: Router) { 
    if(!sessionStorage.getItem('category')){
      this.FetchCategory();
    }else{
      this.categories = JSON.parse(sessionStorage.getItem('category'));
      this.Category(JSON.parse(sessionStorage.getItem('category')));
    }
    if(!sessionStorage.getItem('AllArticle')){
      this.GetArticles();
    }else{
      this.articles = JSON.parse(sessionStorage.getItem('AllArticle'));
      this.Article(JSON.parse(sessionStorage.getItem('AllArticle')));
    }
  }

  ngOnInit(): void {
    window.onresize = () => this.isMobileLayout = window.innerWidth <= 991;
  }

  MoreArticle(){
    this.ArticleNumber +=6;
    this.articleIncrementForMobile +=10;
  }

  ArticleDetail(article: any){
    sessionStorage.setItem('article', JSON.stringify(article));
    sessionStorage.setItem('AllArticle', JSON.stringify(this.articles));
    this.router.navigate(['/resources/' + String(article.data?.articleTitle?.iv).replace(/\s+/g,'-').replace('---','-').toLowerCase()]);
  }

  FetchCategory(){
    this.cmsService.Categories().subscribe((result: any) => {
      this.categories = result.data?.items;
      this.Category(this.categories);
    }, (error: any) => {
      console.log(error);
    }, () => {
    });
  }
  
  Category(category: any){
    let article = new ArticalCategory();
    article.id = categoryViewAllId;
    article.data = new Data();
    article.data.categoryText = new CategoryText();
    article.data.categoryText.iv = "All";
    article.isActive = true;
    let i =0;
    this.selectedCategories.push(article);
    //this.categories[0].isActive=true;
    //this.categories.forEach((v) => { if(i==0){v.isActive = true;this.selectedCategories.push(v);
     //} else {v.isActive = false;} v.articleCount = 0; i++});
    let items = this.categories.find((item) => item.id == categoryViewAllId);
    if (items == undefined)
      this.categories.unshift(article);
    else
      items.isActive = true;
    this.CategoryLength = this.categories.length;
    this.RestCategoryCount = this.CategoryLength - 4;
  }
  GetArticles() {
    this.cmsService.AllArticles().subscribe((result: any) => {
      this.articles = result?.data;
      this.Article(this.articles);
    });
  }

  Article(article:any){
      this.Loader = false;
      this.articles.map((v)=>{ v.isActive = true; });
      this.TopArticle = this.articles.find((item) => item.id == topPageId);
      this.TradingArticle = this.articles.find((item) => item.id == tradingArticleId);
      if(!this.TopArticle)
        this.TopArticle = this.articles.shift();
      if(!this.TradingArticle)
        this.TradingArticle = this.articles.shift();
      this.TradingText = this.TradingArticle?.data?.subheading?.iv
      this.ArticleFitersByOne(this.selectedCategories[0])
      this.IsArticleloaded = true;
      this.DisplayDataArticle(this.filteredArticles);
      this.filtersCategories = JSON.parse(JSON.stringify(this.categories));
  }

  CategorySelection(cat: ArticalCategory) {
    this.ArticleNumber = 6; // default article load
    if (!cat.isActive) {
      cat.isActive = true;
    }
    // this.categories.forEach((v) => {
    //   if (v.id != cat.id && v.isActive) {
    //     v.isActive = false;
    //   }
    // });

    //this.categories.map(v=> v.isActive == (v.id != cat.id && v.isActive));
    this.categories.map((v)=>{ 
      if(v.id != cat.id && v.isActive){
           v.isActive = false;
      }
    });
    this.ArticleFitersByOne(cat);
  }

  ArticleFitersByOne(cat: ArticalCategory) {
    let selectedCategory = cat?.data?.categoryText?.iv;
     if (cat?.id == categoryViewAllId) {
    //   this.articles.forEach((v) => {
    //     v.isActive = true;
    //   });
       this.filteredArticles = this.articles;
     }
     else {
    //   this.articles.forEach((v) => {
    //     let indexvalue = v.data.category.categoryText.findIndex(a => a == selectedCategory);
    //     if (indexvalue != -1)
    //       v.isActive = true;
    //     else
    //       v.isActive = false;
      // });
      this.filteredArticles = this.articles.filter(item => item.data.category.categoryText.findIndex(a => a==selectedCategory) !=-1 );
      //let aa = this.articles.find((item) => item.data.category.categoryText.findIndex(a => a==selectedCategory)!=-1);
      //let aa = this.articles.filter(x => x.isActive == true);
    }}
  

  CategoryClicked(category){
    this.categories.forEach((v) => {
      if (category.id == categoryViewAllId) {
        v.isActive = false;
      }
      else if(category.id != categoryViewAllId){
        if(v.id == categoryViewAllId)
          v.isActive = false;
      }
    });

    if (!category.isActive) {
      category.isActive = true;
    }else{
      category.isActive = false;
    }

    const catIndex=this.selectedCategories.indexOf(category);
    if(catIndex==-1){
      if ((category.id == categoryViewAllId) || (this.selectedCategories.length==1 && this.selectedCategories[0].id==categoryViewAllId)) {
        this.selectedCategories=[];
      }
      this.selectedCategories.push(category);
    }
    else{
      this.selectedCategories.splice(catIndex,1);
    }
  }

  ApplyCategories(){
    if(this.selectedCategories.length==0){
      this.DisplayDataArticle(this.articles);
    }
    else{
      this.filteredArticles =[];
      //this.filteredArticles = this.articles.filter(item => item.data.category.categoryText.findIndex(a => a==this.selectedCategories) !=-1 );
      if(this.selectedCategories[0].id == categoryViewAllId){
       this.filteredArticles = this.articles; 
      }else{
        this.articles.filter(e=>this.ArticleExitInCategory(e));
      }

      
      
      //this.filteredArticles.push(this.articles.filter(item => { this.selectedCategories.filter((y)=>{
        //console.log(item.data.category.categoryText.findIndex(a => a==y.data.categoryText.iv));
        //item.data.category.categoryText.findIndex(a => a==y.data.categoryText.iv) >=0 })}));

      this.DisplayDataArticle(this.filteredArticles);
    }
  }

  DisplayDataArticle(data: Array<any>) {    
    this.articleIncrementForMobile = 0;
    if (data != null && data.length > 0) {      
      this.articleIncrementForMobile = 10;
      this.filteredArticles = data;
      this.articlesLoading=false;
    }
    else {      
      this.filteredArticles = [];
      this.articlesLoading = false;
    }
  }

  IsHighlighted(category){
    return this.selectedCategories.indexOf(category);
  }

  ArticleExitInCategory(e){
    // this.filteredArticles = 
    //   this.articles.filter(item => {
    //   this.selectedCategories.filter((y)=>{
    //     item.data.category.categoryText.findIndex(a => a==y) !=-1 })});

    (e.data.category?.categoryText as any[])?.filter((x)=>{
      this.selectedCategories.filter((y)=>{
        if(x == y.data.categoryText.iv){
        //if(x === y?.data.category.iv){
         this.filteredArticles.push(e);
          return true;
        }
        else{
          return false
        }
      })
    })
  }
}
