<app-navbar-white></app-navbar-white>
<div class="spinner-container-box" *ngIf="Loader">
<div class="spinner-container">
  <div class="spinner-border spinner-border-sm" role="status"></div>
  <div class="text-loader">
    Please wait while we are gathering data...
  </div>
</div></div>
<div class="section-conatiner" *ngIf="!Loader">
<div class="resource-section">
<div class="faq-heading">Welcome to the <b>Blossm</b> <span> Resources</span></div>
<div>
<img loading="lazy" class="img-fluid lazyload" id="section-bg" src="{{TopArticle?.data?.showPageImageLink?.iv}}" alt="image not found">
<div class="position-new">
<div class="bg-box">
<div class="d-flex d-b">
<div class="box-count">  
<div class="tittle-article">{{TopArticle?.data?.category?.categoryText[0]}}</div>
<div class="d-flex width-boxf"><img loading="lazy" class="img-fluid lazyload img-vector" src="{{imgSrc}}Vector-clock.svg" alt="image not found">
<div class="mins-count">{{TopArticle?.data?.readTime?.iv}} read</div></div></div>
<div class="box-heading" (click)="ArticleDetail(TopArticle)">{{TopArticle?.data?.articleTitle?.iv}}</div>
<div class="read-txt d-web" (click)="ArticleDetail(TopArticle)">READ</div>
</div> 
</div></div>
</div>
</div>
<div class="width-r container-fluid">
<div class="drop-shadow-box d-web">
<div class="d-flex">
<div class="col-6 padding-box">
<div class="d-flex">
<div class="tittle-article credit">{{TradingArticle?.data?.category?.categoryText[0]}}</div>
<div class="d-flex  width-box"><img loading="lazy" class="img-fluid lazyload img-vector" src="{{imgSrc}}Vector-clock.svg" alt="image not found"><div class="mins-count">{{TradingArticle?.data?.readTime?.iv}} read</div>
</div>
</div>
<div class="text-heading">{{TradingArticle?.data?.articleTitle?.iv}}</div> 
<div class="para-txt">{{(TradingText?.length>160)? (TradingText | slice:0:160)+'...':(TradingText)}}</div> 
<div class="read-txt" (click)="ArticleDetail(TradingArticle)">READ</div>
</div>
<div class="col-6 padding-right">
<img loading="lazy" class="img-fluid lazyload border-r" src="{{TradingArticle?.data?.showPageImageLink?.iv}}" alt="image not found">
</div>                  
</div></div>
 
<div class="article-box d-mobile">
<img loading="lazy" class="img-fluid lazyload img-a" src="{{TradingArticle?.data?.showPageImageLink?.iv}}" alt="image not found">
<div class="text-section">
<div class="d-flex"><span class="tittle-article credit">{{TradingArticle?.data?.category?.categoryText[0]}} &nbsp;</span> <img loading="lazy" class="img-fluid lazyload img-vector" src="{{imgSrc}}Vector-clock.svg" alt="image not found">
<div class="mins-count">{{TradingArticle?.data?.readTime?.iv}} read</div></div>
<div class="para-article">{{TradingArticle?.data?.articleTitle?.iv}}</div>
<div class="read-txt" (click)="ArticleDetail(TradingArticle)">READ</div>
</div>
</div>

<div class="resource-section">
<div class="d-web">   
<div class="d-flex">
<button *ngFor="let cat of categories|slice:0:9" (click)="CategorySelection(cat);" [ngClass]="cat.isActive?' basic-button active-btn':' basic-button'">
        {{cat.data.categoryText.iv}}
</button>
</div>
<div class="row">
<div class="col-4" *ngFor="let art of filteredArticles|articlefilter:{isActive:true}:ArticleNumber">
<div class="article-box">
<img loading="lazy" class="img-fluid lazyload img-a" src="{{art?.data?.showPageImageLink?.iv}}" alt="image not found">
<div class="text-section">
<div class="d-flex"><img loading="lazy" class="img-fluid lazyload img-vector" src="{{imgSrc}}Vector-clock.svg" alt="image not found">
<div class="mins-count">{{art?.data?.readTime?.iv}} read</div></div>
<div class="para-article">{{art?.data?.articleTitle?.iv}}</div>
<div class="read-txt" (click)="ArticleDetail(art)">READ</div>
</div>
</div></div></div>
</div>

<div class="d-mobile">
<div class="d-flex justify-content-between">
<div class="aricles-all">All Articles</div>
<div class="Filter-txt" data-toggle="modal" data-target="#exampleModalCenter"><i class="fa fa-filter" aria-hidden="true"></i> Filter</div>
</div>

<div class="drop-box-mobile" *ngFor="let art of filteredArticles | slice:0:articleIncrementForMobile; index as i">
<div class="d-flex" (click)="ArticleDetail(art)">
<div class="col-8 padding-left">
<div class="d-flex width-box"><img loading="lazy" class="img-fluid lazyload img-vector" src="{{imgSrc}}Vector-clock.svg" alt="image not found">
<div class="mins-count">{{art?.data?.readTime?.iv}} read</div></div>
<div class="para-article-0">{{art?.data?.articleTitle?.iv}}</div>
</div>
<div class="col-4 padding-left padding-right text-right">
<img loading="lazy" class="img-fluid lazyload img-md" src="{{art?.data?.showPageImageLink?.iv}}" alt="image not found">  
</div>
</div>   
</div>

</div>
<div class="btn-container" *ngIf="filteredArticles.length>=ArticleNumber || filteredArticles.length>=articleIncrementForMobile"><button type="button" class="btn-more" (click)="MoreArticle()">MORE</button></div>
</div>
</div>
</div>
<!-- Modal -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Filter by</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <button *ngFor="let cat of categories;let i=index"  (click)="CategoryClicked(cat)" [ngClass]="cat.isActive?'btn basic-button active-btn':'btn basic-button'">
                {{cat.data.categoryText.iv}}
            </button>
        <div class="btn-apply-con">
            <button class="btn btn-apply" (click)="ApplyCategories()" data-dismiss="modal" type="button">APPLY</button>
        </div>
        </div>
      </div>
    </div>
  </div>
<app-footer-new></app-footer-new>

