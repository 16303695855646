<div class="fixed-top" id="navbar">
<nav class="navbar navbar-expand-lg sticky-top ">
<a href="../home" class="navbar-brand">
<img loading="lazy" class="img-fluid lazyload img-logo-size" src="{{imgSrc}}blossm_logo.svg" alt="image not found">
</a>

<button id="navButton" class="navbar-toggler navbar-toggler-right collapsed" type="button" data-toggle="collapse"
data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" >
<span class="close" id="close" ><img class="img-width img-fluid lazyload" src="{{imgSrc}}menu.svg" alt=""></span>
<span class="navbar-toggler-icon"><img class="ing-fluid img-width" src="{{imgSrc}}menu.svg" alt="image not found"></span>
</button>
<div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
<ul class="navbar-nav">
<li class="nav-item"><a routerLink="/for-employers" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"  class="nav-link">For Employers</a></li>
<li class="nav-item"><a class="nav-link" routerLink="/resources" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Education</a></li>
<li class="nav-item"><a routerLink="/about-us" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">About</a></li>
<li class="nav-item"><a routerLink="/faqs" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">FAQ’s</a></li>
<li class="nav-item"><a href="https://blog.blossm.me/" target="_blank" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">Blog</a></li>
<li class="nav-item">
<a class="signuplink" href="{{SignUP}}" target="_blank"><button class="btn-sign-up">LOG IN</button></a> </li>
</ul>
</div>

</nav>
</div>