// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import * as config from '../assets/app-config.json';
export const environment = {
  production: false,
  name: 'Local',
  configUrl:config.default,
  imgBasURL:"../../assets/blossm-assets/",
  baseUrl: "https://api.dev.ooki.io",
  appBaseUrl: "https://app.dev.ooki.io",
  baseUrlProd:"https://api.dev.ooki.io/public-auth/Crowdfunding",
  cmsBaseUrl:"https://cms.ooki.io",
  apiUrl: "https://app.ooki.io/login",
  signUrl:"https://app.ooki.io/sign-up-create-password",
  GoogleStore:"https://play.google.com/store/apps/details?id=com.apps.scoregenius",
  PlayStore:"https://www.apple.com/in/ios/app-store/",
  blossmUrl: "https://app.dev.blossm.me",
  blossmWeburl: "https://dev.blossm.me",
  SocialMediaSharing: "https://dev.blossm.me/assets/blossm-assets/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
