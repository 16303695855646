import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ResourceComponent } from './resource/resource.component';
import { ResourceArticleInnerComponent } from './resource-article-inner/resource-article-inner.component';

const routes: Routes = [
  {path:'resources', component:ResourceComponent },
   {path:'resources/:ArticleName', component:ResourceArticleInnerComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EducateRoutingModule { }
