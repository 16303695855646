import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { DomSanitizer } from '@angular/platform-browser';
import { ArticleService } from 'src/app/Service/article.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-resource-article-inner',
  templateUrl: './resource-article-inner.component.html',
  styleUrls: ['./resource-article-inner.component.css']
})
export class ResourceArticleInnerComponent implements OnInit {
  imgSrc = environment.imgBasURL;
  blossmUrl = environment.blossmWeburl; 
  imgMediaSharing = environment.SocialMediaSharing
  Article : any;
  AllArticles: any;
  relatedArticles: any;
  width: number = 800;
  height: number = 786;
  SharingLink: string;
  RefLink: boolean = false;
  Loader: boolean = true;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private sanitizer: DomSanitizer, private cmsService: ArticleService) { 
    this.activatedRoute.queryParams.subscribe(result => {
      if (result.refId) {
        this.RefLink = true;
        sessionStorage.setItem('ReferralVia',  result.ReferralVia);
        this.loadArticle(result.refId);
      }else{
        this.Loader = false;
      }
    })
  }

  loadArticle(refId: any) {
    this.GetArticles(refId);
    this.FetchCategory();
  }

FetchCategory(){
    this.cmsService.Categories().subscribe((result: any) => {
      sessionStorage.setItem('category', JSON.stringify(result.data?.items));
    });
  }

  GetArticles(refId : any) {
    this.cmsService.AllArticles().subscribe((result: any) => {
       sessionStorage.setItem('AllArticle',JSON.stringify(result?.data));
       sessionStorage.setItem('article', JSON.stringify(result?.data?.find((item)=> item.id == refId)));
       setTimeout(() => {}, 200); // set timeout to load data
       this.RelatedArticles();
       this.Loader = false;
    });
  }

  ngOnInit(): void {
    if(!this.RefLink)
      this.RelatedArticles();
  }

  RelatedArticles(){
    if(sessionStorage.getItem('article'))
    {
      this.Article = JSON.parse(sessionStorage.getItem('article')); 
      this.AllArticles = JSON.parse(sessionStorage.getItem('AllArticle')); 
      this.FillData();
      this.SharingLink = this.blossmUrl  + '/resources/' + String(this.Article.data?.articleTitle?.iv).replace(/\s+/g,'-').replace('---','-').replace('?','').toLowerCase()+ '?%26refId=' + this.Article.data?.id + '%26ReferralVia=';
    }
    else
      this.router.navigate(['/educate']);
  }

  FillData(){
    this.Article!.data!.articleText!.iv = this.sanitizer.bypassSecurityTrustHtml(documentToHtmlString(this.Article?.data?.articleText?.iv));
    if (this.Article?.data?.refRelatedArticles?.iv) {
      this.relatedArticles = JSON.parse(JSON.stringify(this.Article?.data?.refRelatedArticles?.iv))
    }
    else {
      this.relatedArticles = [];
      this.cmsService.ArticleBySlug(this.Article?.data?.slug).subscribe((result: any) => {
        this.relatedArticles = JSON.parse(JSON.stringify(result?.data?.data?.refRelatedArticles?.iv)) as Array<any>
      })
    }
  }
  AnchorClick(e: any) {
    if ((e.target as HTMLElement).tagName === 'A') {
      e.stopPropagation();
      e.preventDefault();
      let target = e.target as HTMLElement;
      var url = target.getAttribute("href");
      if (url != '#') {
        window.open(url, '_blank');
      }
    }
  }
  DisplayRelatedArticle(RelativeArticleId: any){
   this.Article = this.AllArticles.find((item) => item.id == RelativeArticleId); 
   this.FillData();
  this.SharingLink = this.blossmUrl + '/resources/' + String(this.Article.data?.articleTitle?.iv).replace(/\s+/g,'-').replace('---','-').replace('?','').toLowerCase()+ '?%26refId=' + this.Article.data?.id + '%26ReferralVia='; 
   setTimeout(() => {}, 200); // set timeout to load image
   window.scrollTo(0, 0);
  }

  redirect(Type: any) {
    var left = (screen.width / 2) - (this.width / 2);
    var top = (screen.height / 2) - (this.height / 2);
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if (Type == 'facebook') {
      if (width <= 767) {// This condition works on Mobile Device
        setTimeout(() => {
          window.location.href = "https://www.facebook.com/sharer/sharer.php?u=" + this.SharingLink + Type;
        }, 200);
      }
      else {
        window.open("https://www.facebook.com/sharer/sharer.php?u=" + this.SharingLink + Type, 'mywindow', 'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,scrollbars,width=' + this.width + ',height=' + this.height + ',top=' + top + ',left=' + left);
      }
    }

    else if (Type == 'twitter') {
      if (width <= 767) {// This condition works on Mobile Device
        setTimeout(() => {
          window.location.href = "https://twitter.com/share?url=" + this.SharingLink + Type;
        }, 200);
      }
      else {
        window.open("https://twitter.com/share?url=" + this.SharingLink + Type, 'mywindow', 'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,scrollbars,width=' + this.width + ',height=' + this.height + ',top=' + top + ',left=' + left);
      }
    }
    else if (Type == 'pinterest') {
      if (width <= 767) {// This condition works on Mobile Device
        setTimeout(() => {
          window.location.href = "https://in.linkedin.com/share?url=" + this.SharingLink + Type + '&media=' + this.imgMediaSharing + 'Blossm-OG-Image.jpg';
        }, 200);
      }
      else {
        window.open("https://in.linkedin.com/share?url=" + this.SharingLink + Type + '&media=' + this.imgMediaSharing + 'Blossm-OG-Image.jpg', 'mywindow', 'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,scrollbars,width=' + this.width + ',height=' + this.height + ',top=' + top + ',left=' + left);
      }
    }

  }
}
