import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.css']
})
export class ErrorPageComponent implements OnInit {
  imgSrc = environment.imgBasURL;
  constructor() {
    // Do nothing
   }

  ngOnInit(): void {
    // Do nothing
  }

}
