import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-navbar-white',
  templateUrl: './navbar-white.component.html',
  styleUrls: ['./navbar-white.component.css']
})
export class NavbarWhiteComponent implements OnInit {
  imgSrc = environment.imgBasURL;
  API = environment.apiUrl;
  GoogleURL = environment.GoogleStore;
  AppleUrl = environment.PlayStore;
  SignUP = environment.blossmUrl;
  constructor(private router: Router) {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }
  
  @HostListener("window:scroll", [])
  onWindowScroll() {
    var newelement = document.getElementById("navButton");
    if (!newelement.classList.contains('collapsed')) {
      newelement.click();
    }
  }
  
  ngOnInit() {
    window.onscroll = function () { myFunction() };
    var navbar = document.getElementById("navbar");
    function myFunction() {
      if (window.scrollY >= 10) {
        navbar.classList.add("sticky")
      } else {
        navbar.classList.remove("sticky");
      }
    }
  }
  navigation(){
    this.router.navigate(['/']);
  }
}
