<footer id="footer">
<div class="footer-top-bg">
<div id="footer-top" class="container-fluid">
<div class="d-flex flex-wrap">
<div id="Company-container">
<div class="list-group">
<div class="footer-top-link">Find out more about Blossm</div>
<a class="footer-link" href="../for-employers" routerLinkActive="active">For Employers</a>
<a class="footer-link" href="../resources" routerLinkActive="active">Resources</a>
<a class="footer-link" href="../about-us" routerLinkActive="active">About</a>
<a class="footer-link" href="../faqs" routerLinkActive="active">FAQ’s</a>
</div></div>

<div id="contact-container">
<div class="list-group">
<div class="footer-top-link">Get in touch</div>
<a class="footer-link">help@blossm.me</a>
<div class="addres-txt">Crown House, 9 Duke Street, Richmond, TW91HP</div>
<div class="btn-cont"><a href="/contact-us"><button type="button" routerLinkActive="active" (click)="refresh()" [routerLinkActiveOptions]="{exact:true}" class="btn btn-faq">CONTACT US</button></a></div>
<div class="d-flex df">
<img loading="lazy" class="img-fluid lazyload img-social img-width" src="{{imgSrc}}In.svg" alt="image not found">
<img loading="lazy" class="img-fluid lazyload img-social img-width" src="{{imgSrc}}Skype.svg" alt="image not found">
<img loading="lazy" class="img-fluid lazyload img-width" src="{{imgSrc}}Insta.svg" alt="image not found">
</div> 
</div></div>

<div id="legal-compliance-container">
<div class="list-group">
<div class="footer-top-link">More info</div>
<a class="footer-link" routerLinkActive="active" [routerLink]="['/terms-conditions']" target="_blank" [routerLinkActiveOptions]="{exact:true}" > Terms & Conditions </a>
<a class="footer-link" routerLinkActive="active" [routerLink]="['/privacy-policy']" target="_blank" [routerLinkActiveOptions]="{exact:true}" > Privacy Policy </a>
<a class="footer-link" routerLinkActive="active" [routerLink]="['/how-to-complain']" target="_blank" [routerLinkActiveOptions]="{exact:true}" > How to Complain</a>
<a class="footer-link" routerLinkActive="active" [routerLink]="['/cookie-policy']" target="_blank" [routerLinkActiveOptions]="{exact:true}" > Cookie Policy</a>
</div></div>

</div></div></div>

<div id="footer-bottom-txt-container">
<div id="footer-bottom-txt">
  Copyright © 2023 Blossm. All rights reserved. Blossm is a trading style of Score Genius Technology LTD. Blossm is an authorised representative of Creative Finance Corp LTD under reference FRN840328 & FRN830950. Creative Finance Corp LTD is authorised and regulated by the financial conduct authority under reference FRN 702435, and is the principal firm of Blossm. </div></div>
</footer>


<!------Modal start-->
<div class="modal fade" id="Cookiepolicy" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title top-heading" id="exampleModalLongTitle">Cookie Policy</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">   <img src="{{imgSrc}}closeiconcross.svg" alt="image not found"></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="normal-txt">
          Blossm uses cookies for a variety reasons, including ensuring that our website functions in accordance with the requirements of our business, our partners and users; logging your preferences and collecting information on the users of our services, including our website.
      </div>
      <div class="normal-txt">
        When you use our services you will agree that this Cookies Policy, together with our Privacy Policy and Terms and Conditions, are relevant and applicable.  References to our website below include references to our apps.
       </div>
       <div class="normal-txt">
        Protecting you, and any information that we collect about you online, is paramount. Our Cookies Policy informs you about why we use cookies, and how we use Cookies to improve the services we provide.
      </div>

      <div class="sub-heading">
        What are cookies?
      </div>
      <div class="normal-txt">
        Cookies are files containing small amounts of information downloaded to your device. We and our third party service providers use both session cookies and persistent cookies.
       </div>
<div class="session-cookies">
  <ul class="ul-margin">
  <li><span class="bold-font">	Session cookies</span> last for the length of your visit and are deleted as soon as you close your browser. They allow us to follow a user’s journey and identify the movement between pages; support website security and functionality.
  </li>
  <li>
    <span class="bold-font">Persistent cookies</span> stay on your device after your browser has been closed, and enable a website to remember your references and actions, thus improving the service for you. Every time you visit the website where the cookie was generated, they are activated. Persistent cookies can be used by websites to target advertising based on the browsing history of the device. They are stored by the browser and will be valid until their set expiry date.  They can be deleted by the user before that expiry date.
 </li> </ul> </div> 
    <div class="session-cookies space-between">
      Cookies may be either "persistent" cookies or "session" cookies, depending on how long they are used.
    </div>
    <div class="session-cookies space-between">
      The majority of cookies we use are session cookies. They help us to ensure the security of your session, and enable you to remain signed in to your Blossm account as you navigate between pages.
    </div><div class="session-cookies space-between">
      We use cookies for numerous reasons, including improving our website and making it easy for you and all other users to use, and also to remember your preferences.
    </div>
    <div class="session-cookies space-between">
      Your web browser - Google Chrome, Internet Explorer, Safari or Mozilla Firefox, or any similar browser; send cookies back to our website or application every time you visit our website.
    </div>
    <div class="session-cookies">
      We, and our third party service providers, use the information the cookies provide about you for the following purposes:
    </div>
    <div class="session-cookies space-between-ul">
<ul class="ul-margin">
  <li>
   to recognise your device/devices whenever you visit our website and log any preferences you have set on our website, which allows us to personalise our content for you, including using your name
  </li>
  <li>to remember if you’ve previously been informed of and agreed to our Cookies Policy, Privacy Policy and other Terms and Conditions</li>
  <li>to analyse use of our website including for but not limited to, sales and marketing research, and performance and statistical analysis.</li>
  <li>to test out new content and analyse whether it is effective</li>
  <li>to ensure our website is easy to use and up to date</li>
  <li>to temporarily store certain information you give us, for example, as part of customer surveys</li>
  <li>to target information that is most relevant to you, including our notifications, website content, marketing campaigns and direct marketing emails, as well as evaluating our Internet banner advertisements on our website and on third party websites</li>
<li> to monitor traffic to our website and analyse how you navigate within it, such as tracking page usage and paths
  used; and tracking use of our Internet banner advertisements and other links from our marketing partners’
  websites to our website</li>
<li>to allow you to share pages with social networks such as Facebook, Twitter, Instagram and LinkedIn and to
  support social media components, like Facebook or Twitter (where our website uses a plugin from these third
  party platforms)</li>
</ul></div>
<div class="session-cookies space-between">If you prefer us not to use these cookies, then you can disable cookies via a setting in your browser (see information on ‘Turning Cookies Off’ below.)
</div>
<div class="session-cookies space-between">We may use the data we get from Cookies on your web history and usage with other relevant information we have collected from you, or about you. We securely store this information in order for us to have a greater and more focused understanding of how you and other users are using our website, as well as user’s interests and preferences, for the reasons listed above.
</div>
<div class="session-cookies space-cookies"><div class="bold-font">More about the cookies we use</div>The cookies we use are both cookies that we set, which are referred to as first party cookies, and cookies managed for us by third parties.
</div>
<div class="session-cookies space-between">A cookie is either a first or third party cookie depending on the website the cookie comes from.
</div><div class="session-cookies space-between">
First party cookies are created by the host website or domain visited, in this case Blossm.  All other cookies are third party cookies. These cookies log each visit to our website, the pages you visited and the links you followed. They also record how you used the site, for example where you hovered the cursor; how long it took you to click a link etc. We use this information to improve our website and your user experience, and to make the website and advertising shown to you as relevant to your preferences as possible. We may also share this information with third parties for this purpose. We use retargeting and online behavioural advertising to market Blossm which employs targeting cookies to deliver you more targeted advertising.</div>
<div class="session-cookies space-between"><span class="bold-font">Third party cookies: </span> are created by website and domains other than the one you are visiting directly. The cookies collect information in an anonymous form, including the number of visitors to the site, where visitors have come to the website from and the pages they visited, retargeting and ad-serving.  Cookies which are managed for us by third parties, are managed in line with the purposes listed above and cannot be used for any other reason.
</div>
<div class="session-cookies space-between"><span class="bold-font">Web analytics services: </span>we use web analytics services in order understand how you and other users navigate and use our website.  We do this to ensure our services are easy to use and our products are relevant and up-to-date. The benefit is being able to test different page designs or features, to see which is preferred, identify bugs or errors, and see what services we offer are most popular. These web analytics services may be provided by other companies on our behalf. They do this using small invisible images known as “web beacons” or “tracking pixels” that may be included in the digital products and services on our website.
</div>
<div class="session-cookies space-between">By using our website, you agree that we can place both first party and third party cookies and web analytic services on your device.
</div>
<div class="session-cookies space-between"><div class="bold-font">Specific cookies we use</div>
Below is a list of all the cookies we use, what they are used for, and whether they are ‘first party’ or ‘third party’. Where a cookie is a third party cookie, please visit the providers’ website for further information.
</div>
<div class="cookies-explain">Turning Cookies Off</div>
<div class="session-cookies space-between">
You can disable cookies within your browser, however this will mean that parts of our website will no longer function as you would expect and we will not be able to deliver our full service to you.
Browsers allow you to reject cookies and choose to enable do not track features.  Please check the links we have given below to learn how to do that.  
</div><div class="session-cookies space-between">
If you delete cookies any preferences they recorded will be no longer be logged. This will include opting out from cookies, as this requires an opt-out cookie to be set.
</div><div class="session-cookies space-between">
If you are concerned about third party cookies, you can reject those cookies, rather than all cookies, which will enable us to still provide our services to you with the functionality that you would expect and that our full service requires.
</div><div class="session-cookies space-between">
If you would like to stop your browser from accepting cookies, please see information on the following browser pages:
</div>
<div class="session-cookies space-between-ul">
<ul class="ul-margin">
<li>Google: <span class="link-color">https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en</span></li>
<li>Internet Explorer: <span class="link-color"> http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies</span></li>
<li>Chrome: <span class="link-color"> https://support.google.com/chrome/bin/answer.py?hl=en&answer=95647&p=cpn_cookies</span></li>
<li>Safari: <span class="link-color">https://support.apple.com/en-gb/guide/safari/sfri11471/mac</span></li>
<li>Mozilla Firefox: <span class="link-color">http://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</span></li>
</ul>
<div class="session-cookies space-cookies"><div class="bold-font">More about the cookies we use</div>
We reserve the right to make changes to our Cookies Policy. This page will be updates with any changes we may make to our Cookies Policy.  Whenever necessary and appropriate, we will notify you of changes to this policy by email. Please check back here frequently to see any updates or changes. Your continued use of our website is taken as your agreement to any such changes.
</div>
<div class="cookies-explain">Contact Us</div>
<div class="session-cookies space-cookies">If you have any questions about cookies, the cookies we use or our Cookies Policy, please contact us  help@blossm.me</div>

<div class="cookies-explain">Further Reading</div>
<div class="session-cookies space-between">
If you would like to know more about cookies, you can find more information through the links below.
<div  class="link-color">https://www.aboutcookies.org.uk/</div>
<div  class="link-color">http://www.allaboutcookies.org/</div>
<div  class="link-color">http://www.bbc.co.uk/webwise/guides/about-cookies</div>
<div  class="link-color">https://ico.org.uk/media/for-organisations/documents/1545/cookies_guidance.pdf</div>
<div  class="link-color">http://www.youronlinechoices.com/uk/download-guide</div>
</div>
</div></div></div>
</div></div>
<!------Modal How Complain start-->
<div class="modal fade" id="HowComplain" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title top-heading" id="exampleModalLongTitle">How to Complain</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">   <img src="{{imgSrc}}closeiconcross.svg" alt="image not found"></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="sub-heading">
          Complaints handling procedure
        </div>
        <div class="normal-txt">
          Any expression of dissatisfaction about the provision, or failure to provide, a financial service and redress determination/complaints handling procedure which alleges that the complainant has suffered (or may suffer) financial loss, material distress or material inconvenience is treated as a complaint. This includes correspondence in writing and that made during the course of verbal communication. Where any customer expresses concern about the service, steps should be taken to resolve that matter and it should be treated as a complaint.
        </div>
      <div class="normal-txt">
        Complaints may be received directly from customers or from third party representatives. In the event of any complaint the matter must be recorded and logged, and referred to the nominated complaints handler in accordance with our complaint handling procedure.
       </div>
       <div class="normal-txt">
        Any complaint received from a third party representative such as Trading Standards or the Citizens Advice Bureau will be referred to the nominated complaints handler.
      </div>
      <div class="normal-txt">
        It is our aim to always exceed your expectations and we hope you never find cause for dissatisfaction. However, we would encourage you to make us aware of any complaint you may have and give us the opportunity to put it right.
      </div>
      <div class="normal-txt">
        This notice outlines our complaints procedure, the type of complaints it will manage and handle, timescales, the process you should expect us to employ and where to go should we not be able to resolve your complaint, or should you not find a satisfactory resolution to your complaint.
       </div>
      <div class="normal-txt">
        This complaints procedure is aimed at resolving complaints quickly and satisfactorily and further improving the quality of our service. </div>
      <div class="sub-heading">
        What our complaints procedure covers
      </div>
      <div class="normal-txt">
        You may wish to make a complaint with regard to the services which we have provided to you, which include:  </div>
<div class="session-cookies">
  <ul class="ul-margin">
  <li> Manual errors or mistakes </li>
  <li>
    Unexplained delays
   </li> 
  <li> Staff behaviour / conduct</li>
  <li>Breach of contract / terms of business</li>
  </ul> </div> 
    <div class="session-cookies space-between">
      Blossm (or it’s group of companies) is not responsible for any issues or disputes relating to your credit file. This data is owned and operate by our partner Experian. Any queries or complaints that relate to your credit profile should be directed to 
      <a href="https://www.experian.co.uk/consumer/product-factsheets/complaint-handling-procedure.pdf" class="link-color">https://www.experian.co.uk/consumer/product-factsheets/complaint-handling-procedure.pdf</a></div>
      <div class="sub-heading">
        How to make a complaint 
      </div>
      <div class="session-cookies space-between">
      If you are unhappy with the service we have provided to you for any of the above, you should in the first instance discuss the situation with a member of the Operations Team (help@blossm.me). Should a satisfactory resolution not be reached, your complaint will be escalated to the Complaints Management Team. A written acknowledgement will be sent to you within 5 working days of receiving the complaint
    </div><div class="session-cookies space-between">
      In the first instance, should you prefer to, you can write a letter of complaint and post or email it to us. The same timescales will apply. Please find contact information at the bottom of this notice.
    </div>
    <div class="session-cookies space-between">
      If you are making a complaint we recommend you have the following information to hand, as this will allow your complaint to be handled quicker: 
    </div>
    <div class="session-cookies space-between-ul">
<ul class="ul-margin">
  <li>
    Your name and reference number </li>
  <li> Contact details – including preferred time and method of contact</li>
  <li>Nature of your complaint – including dates / timescales involved</li>
  <li>Names of any staff dealing with / involved in your complaint (where known) or provide a description of the staff
    (if possible) where their name is not known</li>
  <li>Any relevant additional information</li>
</ul></div>
<div class="sub-heading">
  How we will action your complaint
</div>
<div class="session-cookies space-between">Our Operations Team are trained to handle your complaint sensitively and efficiently. We will listen to you and seek to understand your experience in detail. We will ask questions and will ensure we have the full picture before taking action or providing a response / resolution.

</div>
<div class="session-cookies space-between">
  We may also need to further investigate your complaint, this might mean we need to speak with employees involved, check system notes, current process, or listen to call recordings before we can action the complaint.
</div>

<div class="session-cookies space-between">
  We aim to have your complaint resolved to your satisfaction at the earliest opportunity, and within 4 weeks from the date we receive your complaint. We will keep you updated on the progress of the measures being taken to resolve the complaint.
</div><div class="session-cookies space-between">
  Once all information has been considered you will be contacted by letter or telephone to confirm whether the company accepts, either completely, or to some degree your complaint.

<div class="session-cookies space-between">
  Where applicable, you will be advised of what steps the company will take to put right the complaint and ensure that the same problem does not reoccur.

</div>
<div class="session-cookies space-between">
  Where more detailed investigation is required the process may take longer than 4 weeks. In any event, we will respond to your complaint in full within 8 weeks.
</div>
<div class="sub-heading">
  Financial Ombudsmen Service
</div>
<div class="session-cookies space-between">
  If you are not satisfied with our final response, you may refer the matter to the Financial
  Ombudsman Service, which can be contacted on the below;
</div>
<div class="session-cookies space-between">
  Telephone: 0800 023 4 567/ 0300 123 9 123</div>
  <div class="session-cookies space-cookies">
    The Financial Ombudsman Service<br>
    Exchange Tower<br>
    London<br>
    E14 9SR <a href="mailto:complaint.info@financial-ombudsman.org.uk" class="link-color">complaint.info@financial-ombudsman.org.uk</a>
    </div><br>
<div class="session-cookies space-between">
  Please note that you have six months from the date of our final response to refer the matter to the Financial Ombudsman Service. We hope that you will accept our decision. If this should not be the case, you remain free at all times to seek an independent form of advice.</div>

<div class="cookies-explain">Contact Us</div>
<div class="session-cookies space-cookies-2">You can write to: Score Genius Technology Limited t/a Blossm</div>
<!---<div class="cookies-explain">Further Reading</div>-->
<div class="session-cookies space-between">
  Crown House, 9 Duke Street,<br>
   Richmond, TW91HP<br>
</div>
<div class="session-cookies space-between">
  You can email:<a href="mailto:complaint.info@financial-ombudsman.org.uk" class="link-color"> help@blossm.me</a>
</div>
</div></div></div>
</div></div>

<!-- Modal Terms & Conditions  -->
<div class="modal fade" id="modaltandc" data-backdrop="static" data-keyboard="false" tabindex="-1"
  aria-labelledby="termLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header custom-modal-header">
        <h5 class="modal-title custom-modal-title">Terms & Conditions</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">
            <img src="{{imgSrc}}closeiconcross.svg" alt="image not found">
          </span>
        </button>
      </div>
      <div class="modal-body custom-modal-body">
        <div class="section-container">
          <p class="para">These terms govern the use of www.blossm.me (our site). By accessing any part of our website or application, users (you, your) are deemed to have accepted these terms and agree to comply with them. Please do not use our site if you do not agree to these terms.</p>
          <p class="para">We recommend that you print a copy of these terms for future reference.</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">Who we are</h3>
          <p class="para">Our site is operated by Score Genius Technology Limited t/a Blossm (we, us, our). We are registered in England and Wales under company number 11498853 and have our registered office at 20-22 Wenlock Road, London, N1 7GU.</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">Other important documents</h3>
          <p class="para">You should review these terms alongside:</p>
          <ul>
            <li>our <a class="blue-txt" routerLinkActive="active" [routerLink]="['/privacy-policy']" target="_blank" [routerLinkActiveOptions]="{exact:true}">Privacy Policy</a> (which sets out how we use personal data collected or generated in connection with our site and products and services); and</li>
            <li> our <a class="blue-txt" data-toggle="modal" data-target="#Cookiepolicy">Cookie Notice</a> (which describes how we use cookies on our site).</li>

          </ul>
          <p class="para">When you apply for any of our products and services, you will be asked to accept other terms and conditions applicable to the products and services you are applying for.</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">Important information about our site</h3>
          <p class="para">
            We do not guarantee that your use of our site will be uninterrupted or error-free. Although we try to ensure that our site is available 24 hours a day, we will not be liable if for any reason our site is unavailable at any time or for any period. We may suspend access to our site temporarily and without notice in the case of system failure, maintenance, repair, for reasons beyond our control, or for other business and operational reasons.</p>
          <p class="para">  The content on our site is provided for general information only. It is not intended to amount to advice or information on which users should rely. No information published on our site constitutes a solicitation or offer, or recommendation, to engage in any trading activity or transactions, or perform any legal act (for example, entering into the terms for our products and services or any other contract). Users must obtain professional or specialist advice before taking, or refraining from taking, any action on the basis of the content on our site.
        </p>
        </div>
        <div class="section-container">
          <h3 class="h-title">Viruses</h3>
          <p class="para">We do not represent or guarantee that our site will be free from loss, corruption, attack, viruses, hacking or other security issues, and we will not be responsible for any losses associated with the types of issues described in this paragraph.</p> 
        <p class="para">You are responsible for configuring your information technology and computer programmes to access our site. You should use appropriate virus protection software. You must not misuse our site by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful.</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">Intellectual property rights</h3>
          <p class="para">We are the owner or the licensee of all intellectual property rights in our site, and in the material published on it. All such rights are reserved. All trademarks, service marks, company names or logos are the property of their respective holders. Any use by you of these marks, names and logos may constitute an infringement of the holders’ rights.
           </p>
           <p class="para">  You may not reproduce, republish, transmit or distribute any material, information or content on our site without our prior written consent. However, you are granted a limited right to access and use our site and retrieve, display and print content pages, for your own personal, non-commercial use.
          </p>
          <p class="para"> Any material transmitted or posted onto our site (including by way of the “Contact Us” form on our site) shall be considered public and non-proprietary and we will have no obligations with respect to such material. We may copy, disclose, distribute, incorporate and otherwise use such material and all data, images, sounds, text and other things embodied in these materials for any and all commercial or non-commercial purposes. We will not do any of the above where the materials constitute or contain personal data.
          </p>
        </div>
        <div class="section-container">

          <h3 class="h-title"> Charges</h3>
          <p class="para">Our site is made available free of charge. We are not responsible for any charges that you may incur from third parties with respect to your use of our site, including without limitation any data charges or Internet access fees.</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">Our liability</h3>
          <p class="para-bold">Whether you are a consumer or a business user:</p>
          <p class="para">
            We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation. To the extent permitted by law, we exclude all conditions, warranties and representations with regard to our site or any content on it, whether express or implied.
          </p>

        </div>
        <div class="section-container">
          <h3 class="h-title">If you are a business user:</h3>
          <p class="para">We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:</p>
          <ul>
            <li>use of, or inability to use, our site; or</li>
            <li>
              use of or reliance on any content displayed on our site.</li></ul>


              <p class="para"> In particular, we will not be liable for:</p>
              <ul>
                <li>loss of profits, sales, business, or revenue;</li>
<li>business interruption;</li>
<li>loss of anticipated savings;</li>
  <li>loss of business opportunity, goodwill or reputation; or</li>
    <li>any indirect or consequential loss or damage.</li>
    </ul>
        </div>
        <div class="section-container">

          <h3 class="h-title">If you are a consumer user:</h3>

          <p class="para">We only provide our site for domestic and private use. You agree not to use our site for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</p>
        </div>
        <div class="section-container">

          <h3 class="h-title">Links to third party sites</h3>

          <p class="para">Links to third party sites on our site are provided solely for users’ convenience. Clicking on these links will cause users to leave our site (linked sites will generally open a new window, our site remaining open). Unless we say otherwise in relation to a particular third party link, we have not reviewed any third party sites and we do not control and are not therefore responsible for these sites or their content or availability, nor do we endorse them or make any representations about them. Users accessing third party sites via these links do so at their own risk and we accept no responsibility for them.</p>

        </div>
        <div class="section-container">

          <h3 class="h-title">Linking to our site</h3>

          <p class="para">You may link to our site’s home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it.</p>
          <p class="para">You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.</p>
          <p class="para">You must not establish a link to our site in any site that is not owned by you.</p>
          <p class="para">Our site must not be framed on any other site, nor may you create a link to any part of our site other than the home page.</p>
          <p class="para">We reserve the right to withdraw permission to link to our site without notice.</p>
        
        </div>
        <div class="section-container">

          <h3 class="h-title">Amending these terms</h3>

          <p class="para">We may revise these terms at our sole and absolute discretion and without prior notice. You should therefore check our site from time to time to review these terms, because, by accessing and continuing to use our site, you will be deemed to have accepted that you are bound by the version of the terms appearing on our site at the time of your access.</p>
        </div>
        <div class="section-container">

          <h3 class="h-title">Governing law and disputes</h3>
          <p class="para">These terms, their subject matter and their formation (and any non-contractual disputes or claims), are governed by English law. To the extent permitted by law, we both agree to the exclusive jurisdiction of the courts of England and Wales.</p>

        </div>
        <div class="section-container">

          <h3 class="h-title">Contact us</h3>

          <p class="para">You can submit feedback, comments, or questions about our products and services, our site, or these terms using the “Contact Us” form on our site. We will be free to exercise any and all rights in any content you submit without restriction or compensation of any kind. Our Privacy Notice describes the personal information we collect through the “Contact Us” form, how we use it, and where it is stored.</p>

        </div>

      </div>
    </div>
  </div>
</div>

<!-- Modal privacypolicy -->
<div class="modal fade" id="privacypolicy" data-backdrop="static" data-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header custom-modal-header">
        <h5 class="modal-title custom-modal-title" >Privacy Policy</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">
            <img src="{{imgSrc}}closeiconcross.svg" alt="image not found">
          </span>
        </button>
      </div>
      <div class="modal-body custom-modal-body">
        <div class="section-container">
          <p class="para">Blossm is a trading name of Score Genius Technology Limited, any references to Blossm are
            references to Score
            Genius Technology, Company Registration Number: 11498853. SGT is authorised and regulated by the Financial
            Conduct Authority under reference FRN 830950.</p>
        </div>
        <div class="section-container">
          <p class="para">References in this Privacy Policy and on our platforms or communications to “we”, “our” or
            “us” are
            references to Score Genius Technology. References to “you” and “your” means each natural or legal person who
            uses our services.</p>

        </div>
        <div class="section-container">
          <p class="para">All references to our website include references to our mobile application.
          </p>
        </div>
        <div class="section-container">
          <p class="para">
            In order for us to provide our unique Personal Finance Management service to you Blossm gathers data from you
            or that you need to give data to us. Protecting your privacy and information is of the utmost importance to
            us. Before using our app or web platform, or registering with us, please take the time to read our Privacy
            Policy in detail. It’s crucial you’re informed about your privacy when using Blossm. Our Privacy and Cookies
            Policies, and our Terms and Conditions are compiled to demonstrate our practices and procedures regarding
            how we gather and undertake the processing of your data.
          </p>
        </div>
        <div class="section-container">
          <p class="para">When using our services via Android/iOS app and our website, you are thereby accepting and
            consenting to
            our practices and procedures described in this Privacy Notice. The data controller of your information is
            Score Genius Technology of 419 Richmond Rd, Twickenham TW1 2EX.</p>
        </div>
        <div class="section-container">
          <p class="para">Our data controller registration number is ZA516139. Our registration on the Data Protection
            Public
            Register can be found at <a href="https://ico.org.uk/esdwebpages/search" target="_blank"><span class="blue-txt">https://www.ico.org.uk/esdwebpages/search</span></a> .</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">Privacy Policy</h3>
          <ul>
            <li>The information we collect</li>
            <li>How we use the information</li>
            <li>Who we may share your data with</li>
            <li>Energy helpline / Fundraising Innovations Ltd</li>
            <li>What’s the legal basis for us using this information</li>
            <li>How long we keep your information for</li>
            <li>How we protect your information</li>
            <li>What direct marketing will you receive</li>
            <li>How you can change your direct marketing preferences</li>
            <li>Links to and from third party websites</li>
            <li>Automated Decision Making</li>
            <li>Your rights</li>
            <li>Changes we make to our Privacy Policy</li>
            <li>Who are the Information Commissioners Office (ICO)</li>
            <li>Your right to lodge a complaint with the ICO</li>
            <li>Contact us</li>
          </ul>
        </div>
        <div class="section-container">
          <h3 class="h-title">1. The information we collect</h3>
          <b>
            <p class="bold-para">We collect information that:</p>
          </b>
          <ul>
            <li>You give us when you sign up with us</li>
            <li>We obtain from your use of our website and app</li>
            <li>We acquire information from Experian and their subsidiary FraudNet</li>
            <li>We receive that other third parties pass us about you</li>
            <li>We obtain through telephone communications</li>
            <li>We obtain from cookies (Please refer to our cookies policy)</li>
          </ul>
          <p class="para">We may collect and process the following data about you:</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">Information you give us.</h3>
          <p class="para">You will give us information about yourself during your use our web platform, app and if you
            communicate
            with us. Also if you tell us about a problem, complete a survey or answer questions, give us feedback or
            participate in forums.</p>
          </div>
          <div class="section-container">
          <span class="para">
            The information you give us may include but is not limited to:</span>
          <ul>
            <li>Title</li>
            <li>Name</li>
            <li>Contact details</li>
            <li>Date of birth</li>
            <li>Income</li>
            <li>Monthly expenditure</li>
            <li>Property value</li>
            <li>Deposit</li>
            <li>Mortgage details</li>
            <li>Energy and gas tariff details</li>
            <li>Address history</li>
            <li>Employment info</li>
            <li>Bank Accounts (Current and Savings)</li>
            <li>Monthly rental payments or income</li>
            <li>Driving license details</li>
          </ul>
        </div>
        <div class="section-container">
          <h3 class="h-title">Special categories of data.</h3>
          <p class="para">If you access some of our services (e.g. credit; insurances) we may ask you for or we may
            obtain special
            categories of data:</p>
          <ul>
            <li>Claims history</li>
            <li>Health Data</li>
            <li>Criminal Convictions</li>
            <li>County Court Judgements</li>
          </ul>
        </div>
        <div class="section-container">
          <span class="bold-para">Information we obtain from your use of our website and app
          </span><br>
          <p class="para">Each and every time you use our app or our website we may collect the following information:
          </p>
        </div>
        <div class="section-container">
          <p class="para"> <span class="bold-para">Information about your visit, </span>including the full Uniform
            Resource Locators (URL) clickstream to, through and from our website which
            includes date and time; products you viewed, products searched for; page response times, download errors,
            length of visits to certain pages, page interaction information (such as scrolling, clicks, and
            mouse-overs) and methods used to browse away from the page and any phone number used to call our customer
            service number.
          </p>
        </div>
        <div class="section-container">
          <p class="para"><span class="bold-para">Technical information, </span> including your login information, the
            Internet protocol (IP) address, time zone setting, operating system,
            browser type, version and platform.</p>
        </div>
        <div class="section-container">
          <p class="para">
            <span class="bold-para">Information we collect from Credit Reference Agencies on your behalf.</span><br>
            If you register to use our services, we will obtain your credit score and credit report from the leading
            Credit Reference Agency - Experian.
          </p>
        </div>
        <div class="section-container">
          <span class="bold-para"> Information we receive that other third parties pass us about you</span>
          <br>
          <p class="para">We work with a number of third parties and they may pass us information about you. These third
            parties
            include but are not limited to business partners, advertising networks, search information providers,
            service providers, analytics providers and social media. They may pass us your credit report, products you have
            taken out or been refused, if you’ve used other products, and other similar details. We may also receive
            information about you if you use any of the websites we operate or the other services we provide.</p>
        </div>
        <div class="section-container">
          <span class="bold-para">Information from telephone calls and other communications </span>
          <br>
          <p class="para">We may collect information from telephone calls and other communications between us, which we
            may monitor
            or record.</p>
        </div>
        <div class="section-container">
          <span class="bold-para">Information collected from cookies.</span>
          <br>
          <p class="para">We collect information about your use of our website from cookies. Please refer to our Cookies
            Policy for
            more on how we use cookies and how turn them off or decline them.</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">2. How we use the information</h3>
          <p class="para">The information we hold in connection to you to communicate with you, provide you our services
            and improve
            on those services. We use your anonymized information for profiling, analysis and research</p>
        </div>
        <div class="section-container">
          <b><span class="bold-para">Information we collect about you based on your use of our website.</span></b>
          <p class="para">We will use this information:</p>
          <ul>
            <li>To make recommendations about products or services that may be of interest</li>
            <li>To improve our services</li>
            <li>To understand the impact of the advertising we show you, and to ensure the advertising is relevant
            </li>
            <li>For internal operations – testing, data analysis, troubleshooting, research, statistical and survey
              purposes</li>
            <li>In our efforts to prevent fraud and keep our website safe and secure</li>
          </ul>
        </div>
        <div class="section-container">
          <b><span class="bold-para">Information you give us, or that we collect on your behalf from Experian, or other
              third parties will
              be used to:</span></b>
          <ul>
            <li>For our identity authentication process to verify your identity</li>
            <li>Run your account and communicate with you by telephone, mail, email, text (SMS) message, or push
              notification</li>
            <li>Provide you with information, services and products</li>
            <li>For profiling and analysis of your credit information to inform you of credit products that we believe
              are likely to
              be of interest to you and are suitable to your credit profile</li>
            <li>Notify you about changes to our services</li>
            <li>Guarantee that content on our platforms is presented in the most effectual method for you and your
              device</li>
            <li>Should you have given your consent for us to market other services and products to you</li>
            <li>Aggregate it on an anonymous basis with other data for data analytical and reporting purposes</li>
            <li>To improve our services</li>
          </ul>
        </div>
        <div class="section-container">
          <span class="bold-para">Information we receive from other sources.</span>
          <p class="para"> We may use information from other source with information you give to us for the purposes
            outlined above.
          </p>
        </div>
        <div class="section-container">
         <span class="bold-para">Recording telephone calls and other communications.</span>
          <p class="para">We may use telephone recordings and/or other communications to improve our services, verify
            your
            instructions to us, analyses and assessment, as well as for training and quality purposes. For investigation
            of any complaint you may make, or as evidence in any dispute between you and us.</p>
          </div>
        
        <div class="section-container">
         <span class="bold-para">What we will never do.</span>
          <p class="para">We will never sell your information to third parties without your consent. Blossm operates a ‘by
            consent’
            policy to enable faster optimised applications for products.</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">3. We may share your data with:</h3>
          <p class="para">Other members of our group and with other third parties, such as our service providers,
            advertisers,
            Experian, and Fraud Prevention Agencies. We may need to share your data to others to enable them to provide
            you with the information, products and services and you request. Your data may be disclosed to the other
            third parties as listed below. These third parties process your information under our instructions. To verify
            your identity, prevent fraud and money-laundering, information we have collected from you will be shared
            with fraud prevention agencies who will use it.</p>
          </div>
          <div class="section-container">
          <p class="para">If fraud is detected, you could be refused finance, services, or employment.</p>
        </div>
        <div class="section-container">
          <b><span class="bold-para">Subprocessors</span></b>
          <p class="para">Under GDPR, a sub-processor is any business or contractor customer information may pass
            through as a
            side-effect of using Blossm services. We use partners for some business processes that are critical to our
            customers having a quality experience. Here is a list of our sub-processors who we may share your
            information with:</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">Sub-processors</h3>
          <p class="para">AWS – Cloud Hosting Provider</p>
          <p class="para">MailChimp – Customer Relationship Management Tool</p>
          <p class="para">Experian – Data Provider for Credit Score and run part of the Market Place</p>
          <p class="para">True Layer – Access to Open Banking</p>
          <p class="para">Energy Helpline – Utilities Swaps</p>
          <p class="para">Amazon Web Services</p>
          <p class="para">Cloud Service</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">Digital Marketing Service Providers</h3>
          <p class="para">We periodically appoint digital marketing agents to conduct marketing activity on our behalf, such activity may result in the compliant processing of personal information.  Our appointed data processors include:</p>
          <ul>
            <li>Prospect Global Ltd (trading as Sopro) Reg. UK Co. 09648733. You can contact Sopro and view their privacy policy here: <a href="http://sopro.io" target="_blank" class="blue-txt">http://sopro.io.</a>  Sopro are registered with the ICO Reg: ZA346877 their Data Protection Officer can be emailed at: dpo@sopro.io.</li>
          </ul>
        </div>
        <div class="section-container">
          <h3 class="h-title">Group company.</h3>
          <p class="para">We may share your personal information with any member of our group, which means our
            subsidiaries, our
            ultimate holding company and its subsidiaries.</p>
        </div>
        <!---<div class="section-container">
          <p class="para">Including:</p>
          <p class="para"> Score Genius Technology</p>
        </div>--->
        <div class="section-container">
          <div class="table-container">
            <div class="table-top">
              Groups of recipients within the specific industry sectors listed
            </div>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th class="th-first">Industry Sector</th>
                  <th>Examples include, but not limited to:</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="td-first">Automotive</td>
                  <td>e.g. car manufacturers, test drives, servicing, breakdown cover</td>
                </tr>
                <tr>
                  <td class="td-first">Charity</td>
                  <td>e.g. animal welfare, disaster relief, children’s medical, elderly, environmental, health</td>
                </tr>
                <tr>
                  <td class="td-first">Leisure</td>
                  <td>e.g. food & drink, events, museums, cinema</td>
                </tr>
                <tr>
                  <td class="td-first">Financial products</td>
                  <td>e.g. banking, credit cards, investments, loans, mortgages, pensions</td>
                </tr>
                <tr>
                  <td class="td-first">Insurance</td>
                  <td>e.g. car, home, life, medical, pet, income protection, travel, warranty products</td>
                </tr>
                <tr>
                  <td class="td-first">Health / Mobility</td>
                  <td>e.g. fitness, beauty, opticians, hearing, care homes, mobility</td>
                </tr>
                <tr>
                  <td class="td-first">Home Improvements</td>
                  <td>e.g. house moving, blinds & curtains, insulation, boilers,conservatories, doors & windows,
                    extensions, gardens,solar panels</td>
                </tr>
                <tr>
                  <td class="td-first">Wills</td>
                  <td>e.g. writing new Wills or reviewing existing Wills</td>
                </tr>
                <tr>
                  <td class="td-first">Funeral Plans</td>
                  <td>e.g. to arrange and pay for a funeral in advance</td>
                </tr>
                <tr>
                  <td class="td-first">Mail Order</td>
                  <td>e.g. catalogues, online retailers</td>
                </tr>
                <tr>
                  <td class="td-first">Market Research</td>
                  <td>e.g. to gather information about consumers’ opinions and preferences</td>
                </tr>
                <tr>
                  <td class="td-first">Media</td>
                  <td>e.g. online, television, radio, newspapers, magazines</td>
                </tr>
                <tr>
                  <td class="td-first">Retail</td>
                  <td>e.g. fashion, groceries, electrical goods, comparison sites, discounts, FMCG</td>
                </tr>
                <tr>
                  <td class="td-first">Telecoms</td>
                  <td>e.g. landline, mobile phones, broadband, digital TV</td>
                </tr>
                <tr>
                  <td class="td-first">Travel</td>
                  <td>e.g. long haul holidays, city breaks, flights, UK breaks, accommodation</td>
                </tr>
                <tr>
                  <td class="td-first">Utilities</td>
                  <td>e.g. gas & electricity switching, other household utilities such as water</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="section-container">
          <b><span class="bold-para">Selected third parties.
            </span></b>
          <p class="para">We may also share your information with selected third parties including:</p>
          <ul>
            <li>Fraud prevention agencies, to verify your identity, prevent fraud and money-laundering and trace those
              responsible (please refer to “Fraud Prevention Agencies” below for further detail)</li>
            <li>Experian, to obtain your credit report and credit score on your behalf and to provide our services to
              you (please
              refer to “Credit Reference Agencies” below for further detail)</li>
            <li>Product providers, such as our business partners who offer you loans, credit cards, insurance,
              mortgages, car
              finance, pensions, investments and other similar products, in order to:</li>
            <li>Pre-fill an application form with the product provider</li>
            <li>Assess your probability of being accepted for a product</li>
            <li>Assess if you are an existing customer and for fraud prevention purposes</li>
            <li>Analysis in order to offer you with better products and services in the future, and for segmentation
              purposes<br>

              Product providers are not given permission to use your data for any other purpose, including marketing
            </li>
            <li>Service providers we use, such as aggregators and comparison sites, who will analyse and profile your
              credit
              information and any other data provided on your behalf, or by you, to find credit products that are likely
              to be
              suited to your financial circumstances and inform you of then through targeted advertising. When you
              search for
              credit offers on our platforms, these service providers will record a ‘soft credit search’ on your credit
              file. This
              record will not be visible to other lenders, so by itself it will not influence their decision to lend to
              you and thus
              will not affect your credit score</li>
            <li>Advertisers that require data, to perform services on our behalf, including selecting and serving
              relevant adverts</li>
            <li>Search engine providers and analytics that enable us to optimize and improve our service</li>
            <li>If we sell or buy any business or assets, in which case we may disclose your personal data to the
              prospective
              seller or buyer of such business or assets (or the buyer or seller’s advisers)</li>
            <li>If we are under a duty to disclose or share your personal data in order to comply with any legal
              obligation, or in
              order to enforce or apply our Terms and other agreements; or to protect the rights, property, or safety of
              Blossm,
              our customers, or others. This includes exchanging information with other companies and organisations for
              the
              purposes of fraud protection and credit risk reduction</li>
            <li>If we or part or all of our assets are acquired by a third party, one of the transferred assets will
              be the personal
              data held by us about our customers</li>
          </ul>
        </div>
        <div class="section-container">
          <b><span class="bold-para">Credit Reference Agencies.</span></b>
          <p class="para">If you register for our services we will obtain on your behalf, in accordance with our Terms
            and
            Conditions, copies of your credit report and credit score from our partner Credit Reference Agency,
            Experian, with whom we will share your information. Fraud Prevention Agencies and used by those
            organisations for the purposes of checking identity, preventing fraud, tracing and collection of debt.
            Experian may also use your data for statistical analysis. If you apply for a product, the lender will
            undertake a full credit check with one of the Credit Reference Agencies and provide you with further privacy
            information for that product.
          </p></div>
                  <div class="section-container">
          <b><span class="bold-para">Experian Ltd ("Experian")</span></b>
          <p class="para">We operate Score Genius Technology Ltd. in conjunction with UK credit reference agency Experian. By submitting your details to us, you are consenting to them being passed to Experian and to us letting you know every time your Experian Credit Score or report data has been updated. Experian may share the information they collect from us with fraud prevention agencies, including Cifas, who will use it to prevent fraud and money-laundering and to verify your identity. Law enforcement agencies may access and use this information. If fraud is detected, you could be refused certain services, finance, or employment. Further details of how your information will be used by Experian and these fraud prevention agencies can be obtained by writing to Experian at Experian Ltd, PO Box 8000, Nottingham, NG80 7WF. Experian and other organisations may access and use the information recorded by fraud prevention agencies from other countries. More information about your rights in relation to the personal data Experian hold can be found in the 'Your rights to how we use your personal information' section of their Privacy Policy. For further information about how your details will be used by Experian, please see the rest of our Privacy Policy and Experian's Privacy Policy :
            <a href="https://termsandconditions.hdd2.co.uk/scoreandreport" target="_blank"><span class="blue-txt">https://termsandconditions.hdd2.co.uk/scoreandreport</span></a>
         </p>
        </div>
        <div class="section-container">
          <b><span class="bold-para">Fraud Prevention Agencies.</span></b>
          <p class="para">We will share your personal information with fraud prevention agencies. If inaccurate or false
            information
            is provided and fraud is identified, details of this fraud will be passed to these agencies and to law
            enforcement agencies, who may also access and use this information. We and other organisations may access
            and use from other countries the information recorded by fraud prevention agencies. (difficult to reword)
          </p>
        </div>
        <div class="section-container">
          <h3 class="h-title">4. Energy helpline</h3>
          <b><span class="bold-para">For the online switches through the website: </span></b>
          <p class="para">Our domestic online energy comparison and switching service is supported by our trusted
            partner,
            Fundraising Innovations Ltd (company number 04426857), trading as energy helpline (registered address - The
            Cart Wagon Lodge, Friday Street Farm, Friday Street, East Sutton, Kent, ME17 3DD) who processes data
            securely and in line with Data Protection regulations. As Data Controller the Partner is responsible for
            capturing the information you enter into a switch application in order to forward it to your chosen
            supplier, to enable them to set up your new energy account(s). Once your data is passed to your chosen
            supplier they will process it in line with their Privacy Policy, which we are not responsible for. For
            further information on how your data is processed please refer to the Partner’s Privacy Policy on their
            website. If you are using our offline inbound switching service, you also need to include the following</p>
        </div>
        <div class="section-container">
          <b><span class="bold-para">For the offline switches through our call centre:</span></b>
          <p class="para">Our domestic telephone-based energy comparison and switching service is provided by our
            trusted Partner
            Fundraising Innovations Ltd (company number 04426857), trading as energy helpline (registered address - The
            Cart Wagon Lodge, Friday StreetFarm, Friday Street, East Sutton, Kent, ME17 3DD) who processes data securely
            and in line with Data Protection regulations. As Data Controller the Partner is responsible for capturing the
            information you enter into a switch application and forwarding it to your chosen supplier in order for them
            to set up your new energy account(s). Once your data is passed to your chosen supplier they will process it
            in line with their Privacy Policy, which we are not responsible for. For further information on how your
            data is processed please refer to the Partner’s Privacy Policy on their website</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">5. What’s the legal basis for us using this information?</h3>
          <p class="para">We have an appropriate legal bases on which to collect, use and share data about you. If you
            have any
            questions about the lawful bases upon which we collect and use your personal data, you can contact our Data
            Protection Officer. help@blossm.me. Our lawful bases may include consent (where you have given consent),
            contract (where processing is necessary for the performance of a contract with you (such as delivering our
            core services to you) and our own legitimate interests. </p>
        </div>
        <div class="section-container">
          <p class="para"><span class="bold-para">Performance of a contract.</span> The information you provide
            us with, and the information we
            collect about you
            from Experian or other third parties enables us to provide you with our services as agreed in our contract
            with you. Without collecting the information you give us we cannot provide our services to you. We need
            certain information from you in order to obtain your credit report and score and to show suitable financial
            offers to you and to provide our other services to you.</p>
        </div>
        <div class="section-container">
          <p class="para">For other types of information, for example, the information we collect based on your use of
            the website
            and information we receive from our sources about you, we process this information for the purposes of
            providing our services to you as agreed in our contract and to enable us to:</p>
          <ul>
            <li>Analyse your behaviour and preferences</li>
            <li>Collect information from devices to be able to improve our services for our customers and improve the
              user
              experience on our website</li>
            <li>Provide a secure website to our customers</li>
          </ul>
          <p class="para"><span class="bold-para">Legitimate interests.</span> We may process your personal data
            for the purposes of our
            legitimate interests or for
            the legitimate interests of our product providers or other suppliers provided that such processing does not
            outweigh your rights and freedoms. For example, we may process your personal information to:</p>
          <ul>
            <li>Provide you with our service and improve upon it</li>
            <li>Comply with laws that apply to us</li>
            <li>Offer you tailored products</li>
            <li>Protect you and us from fraud or other threats</li>
            <li>Conduct analysis, segmentation and profiling of your data in order to provide you with direct
              marketing
              communications and quality control </li>
            <li>Manage our customer relationships</li>
          </ul>
        </div>
        <div class="section-container">
          <p class="para">
            Where we rely on legitimate interests, you have the right to object at any time.
          </p>
        </div>
        <div class="section-container">
          <p class="para">
            <span class="bold-para"> Consent.</span> Where we are legally required to do so we will always seek your
            consent to process certain types
            of information. You have the right to withdraw or decline your consent at any time.
          </p>
        </div>
        <div class="section-container">
          <h3 class="h-title">6. How long we keep your information for</h3>
          <p class="para">While your account remains active, we keep your information for no longer than is necessary
            depending on
            the purpose for which we are using it. How long we keep your information will depend on the purpose for which
            we use it. While you are a customer of ours, we will only retain your information for as long as is
            necessary for those purposes. After termination of your account, we may continue to use anonymised data
            (which does not identify individual users) which is aggregated with anonymised data of other users. We use
            this aggregated anonymised data for data analysis, profiling and research purposes, for example to gain
            insights about our users. We may also keep your email address to ensure that you no longer receive any
            communications from us as well as your name, date of birth and latest address for fraud prevention purposes
            and for the exercise or defence of a legal claim.</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">7. How we protect your information</h3>
          <p class="para">We take date security very seriously and use strict procedures and security to protect your
            information and
            prevent unauthorized access, loss or damage. All information you provide to us, or is provided to us about
            you, is stored on our secure servers.</p>
        </div>
        <div class="section-container">
          <p class="para">We cannot currently guarantee the security of your data transmitted to our website; any
            transmission is at
            your own risk. Where possible, we try to only process your information within the UK and European Economic
            Area (EEA). If we or our service providers transfer personal data outside of the UK or EEA, we always
            require that appropriate safeguards are in place to protect the information when it is processed. Part of
            our customer services function is based in India. We have put in place safeguards to protect personal data
            processed in or accessed from India.</p>
        </div>

        <div class="section-container">
          <h3 class="h-title">8. What direct marketing you’ll receive </h3>
          <p class="para">We offer you the possibility to receive direct marketing information from us. Whenever you
            want, you can
            easily opt out of receiving marketing from us. We’ll normally send direct marketing to you by email, and
            with push notifications to your devices and we may contact you via other methods, for example SMS. You may
            receive the following types of communications from us:</p>
        </div>
        <div class="section-container">
          <ul>
            <li><b><span class="bold-para">Core communications-</span></b> we will send you alerts whenever there is a
              change to your
              credit report, significant
              changes which may impact our service and security alerts pertaining to your Blossm account and other such
              related
              content. As these communications are such an intrinsic part of using Blossm they cannot be opted-out of.
            </li>
            <li>
              <b><span class="bold-para">Product recommendations-</span></b> if you explicitly consent to receiving
              them, we’ll make
              personalised product
              recommendations which could help you improve your financial situation. You can unsubscribe whenever you
              like.
            </li>
            <li><b><span class="bold-para">Content communications-</span></b> we’ll send you content such as but not
              limited to tips,
              news, features and on how to
              make the best of your money and other similar content. You can unsubscribe from these at any time. At Blossm
              we
              are anti-spam and will never spam you.</li>
          </ul>
        </div>
        <div class="section-container">
          <h3 class="h-title">9. How You Can Change Your Preferences</h3>
          <p class="para">If you would like us to stop receiving direct marketing from us, we offer easy ways foryou to
            opt out.
            Whenever you receive direct marketing you will be given an option tounsubscribe.</p>

        </div>
        <div class="section-container">
          <p class="para">You can also write to us and tell us that you do not wish to receive any more marketing
            communications,
            with your full name, address and any other contact details you can provide, to:
          </p>
        </div>
        <div class="section-container">

          <p class="bold-para"><b>
              Data Protection Officer<br>
              Score Genius Technology Limited<br>
              419 Richmond Rd,<br>
              Twickenham<br>
              TW1 2EX
            </b></p>
        </div>
        <div class="section-container">
          <h3 class="h-title">10. Links to and from third party websites</h3>
          <p class="para">Please be aware that if you follow a link to or from our website to a third party website,
            including links
            from websites of our partner networks, advertisers and affiliates these websites have their own privacy
            policies. If you follow a link to or froma third party website, and that we do not accept any responsibility
            or liability for these privacy policies.</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">11. Automated decision making</h3>
          <p class="para">We use an automated decision making system to make automated decisions based on personal
            information we
            have about you. This helps us to make sure our decisions are quick and fair, based on what we know.
          </p>
        </div>
        <div class="section-container">
          <p class="para"><span class="bold-para">Identity verification</span><br>
            In order to provide you our services, we must be able to verify your identity. We use an automated decision
            making system to verify the details you provide against those held by third party providers. If you do not
            pass the check using the automated system, we cannot provide our services to you.
          </p>
        </div>
        <div class="section-container">
          <p class="para">
            <span class="bold-para">Relevant communications</span><br>
            We will use your information to determine the likelihood that your application will be accepted for a
            product, or to ascertain the best manner to present products to you.

          </p>
        </div>
        <div class="section-container">
          <p class="para">

            <b><span class="bold-para">Specific products and services</span></b> <br>
            We will use your information to determine the likelihood that your application will be accepted for a
            product, or to ascertain the best manner to present products to you.

          </p>
        </div>
        <div class="section-container">
          <p class="para">You have the right not to be subject to a decision based solely on automated processing,
            including
            profiling. If you prefer to have a non-automated decision, please inform us. Or if you have any questions
            about automated decision making, please contact us at help@blossm.me</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">12. Your rights</h3>
          <p class="para">You can exercise specific rights with regards to the data that we hold about you.
            We will provide you with the necessary tools and contact details to be able to exercise your statutory
            rights regarding the information that we hold about you. You will be able to:
          </p>
        </div>
        <div class="section-container">
          <p class="para"><span class="bold-para">Update or correct your information:</span><br>
            through the settings in your account, you will be able to update, or amend any information that we hold
            about you that is incomplete or inaccurate
          </p>
        </div>
        <div class="section-container">
          <p class="para">
            <span class="bold-para">Request access to your information</span><br>
            you can ask for access to the personal data that we hold about you so that you can check that we are using
            your information in accordance with data protection law.

          </p>
        </div>
        <div class="section-container">
          <p class="para">
            <span class="bold-para"> Delete your information</span><br>
            you can ask us to delete your personal information, which we will do unless it is necessary for us retain it
            to comply with our legal obligations. Once deleted, it is unlikely we will be able to provide our services.
            We may keep your email address to make sure the restriction is respected in future.

          </p>
        </div>
        <div class="section-container">
          <p class="para">
            <span class="bold-para"> Object to the use of your information</span><br>
            you may ask that we no longer use yourpersonal information where that use is based on a legitimate interest.

          </p>
        </div>
        <div class="section-container">
          <p class="para">
            <span class="bold-para"> Restrict the use of your data</span><br>
            You have the right to ‘block’ or suppress further use of your information in certain circumstances (for
            example, where you think the information we are using about you is inaccurate, whilst we verify its
            accuracy). When usage is restricted, we can still store your information, but may not use it further.

          </p>
        </div>
        <div class="section-container">
          <p class="para">
            <span class="bold-para">Download your data</span><br>
            you can obtain a copy of the data you provided us in a machine-readable format.

          </p>
        </div>
        <div class="section-container">
          <p class="para">
            <span class="bold-para"> Send it to another controller</span><br>
            where certain conditions apply, you have the rightto have such information transferred directly to a third
            party.

          </p>
        </div>
        <div class="section-container">
          <p class="para">
            <span class="bold-para"> Right to withdraw consent:</span><br>
            You can exercise your rights at any time, and withdraw your consent for us to use your personal data by
            contacting our Data Protection Officer help@blossm.me.
          </p>
        </div>
        <div class="section-container">
          <h3 class="h-title">13. Changes We Make to our Privacy Policy</h3>
          <p class="para">This page will be updated with any changes we may make to our Privacy Policy and where
            appropriate and
            necessary, you will be notified about changes by email. Please check this page frequently for any updates or
            changes to our Privacy Notice.</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">14. Who are the Information Commissioners Office (ICO)</h3>
          <p class="para">The ICO is the UK’s independent body set up to uphold information rights. </p>
        </div>
        <div class="section-container">
          <h3 class="h-title">15. Your Right to Lodge a Complaint with the ICO</h3>
          <p class="para">If any complaint you raise with us is not dealt with in a satisfactory manner, or you believe
            we are not
            processing your personal data in compliance with data protection law, we ask that you contact our Data
            Protection Officer. However, you may choose to make a complaint to the Information Commissioner’s Office
            (ICO) at any time. Please see the ICO website for further information
            - <a href="https://ico.org.uk/for-the-public/raising-concerns" target="_blank"> <span class="blue-txt">https://ico.org.uk/for-the-public/raising-concerns</span></a> </p>
        </div>
        <div class="section-container">
          <h3 class="h-title">16. Contact Us</h3>
          <p class="para">Please send any questions, complaints, comments or requests about this Privacy Policy to the
            Data Protection Officer at help@blossm.me.</p>
        </div>
      </div>

    </div>
  </div>
</div>
